import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Intersection from '../components/Intersection';
import Popup from '../components/Popup';
import '../styles/about-us.scss';

const WaitList = () => {
  const [modalVisible, setModalVisible] = useState('');

  const togglePopup = useCallback(
    (value) => setModalVisible((state) => (state === value ? '' : value)),
    [],
  );
  const closePopup = useCallback(() => setModalVisible(0), []);

  useEffect(() => {
    modalVisible
      ? document.body.classList.add('overflow')
      : document.body.classList.remove('overflow');
  }, [modalVisible]);

  return (
    <Layout togglePopup={togglePopup}>
      <SEO title="Waitlist" />
      <Popup modalVisible={modalVisible === 'spaces'} closePopup={closePopup}>
        <div className="space-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            src="/video/spaces.mp4"
            style={{ width: '500px' }}></video>
        </div>
        <h4>Spaces by Brutask</h4>
        <p>
          Spaces by Brutask is created to replicate your virtual office.
          Navigate across floors, join a room &amp; discuss with your team via
          audio &amp; video. You can also share your screen with all othe
          participants inside the room. What’s better - you can share the room
          link with external members outside your team, and they can join
          without any login!
        </p>
        <a className="button" href="http://app.brutask.com/">
          Get started
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
              fill="white"
            />
          </svg>
        </a>
        <a
          className="button button-unstyled"
          href="https://www.loom.com/share/c0ad1d9ddb6f48fe9e1040c7f6550ca3"
          target="_blank"
          rel="noopener noreferrer">
          Watch Demo
        </a>
      </Popup>
      <Popup
        modalVisible={modalVisible === 'pricing'}
        closePopup={closePopup}
        className="popup-center">
        <h3>
          Pay as you <span className="yellow">grow</span>
        </h3>
        <p>
          You will be charges <span className="semi-bold">$15/month</span> for
          the plan that will include: 10 members, 3 floors and unlimited guest
          access. <span className="semi-bold">$2</span> would be charged for
          every additional member
        </p>
        <a className="button" href="http://app.brutask.com/">
          Get started
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
              fill="white"
            />
          </svg>
        </a>
      </Popup>
      <div className="about-us-main">
        <div className="container">
          <div className="about-us-block relative">
            <h3>A little backstory</h3>
            <div className="about-us-image">
              <img
                src="https://i0.wp.com/blog.brucira.com/wp-content/uploads/2022/03/Brucira-hoodie-1.jpg?w=1920"
                alt="Brucira hoodie"
              />
            </div>
            <p>
              Our team at{' '}
              <a
                href="https://www.brucira.com/"
                target="_blank"
                rel="noopener noreferrer">
                Brucira
              </a>{' '}
              is habituated to daily stand-ups and task callouts. Before the
              pandemic hit us, we felt the need for our own collaborative task
              management tool that was simple &amp; effective for our small
              team. That’s when we created Brutask, a simple online to-do list
              and task manager.
            </p>
            <p>
              We added our tasks for the day, assigned managerial roles &amp;
              carried out the entire process of stand-up from the comfort of our
              homes. But that’s when it striked our minds - if this tool is able
              to sort out our daily tasks &amp; supervision requirement, this
              can surely help other teams as well.
            </p>
            <p>
              Hence, we launched Brutask and have been able to successfully
              register 3000+ teams and individuals on our platform, who use our
              tool like their ‘Breakfast before work - the tool useful for
              planning out their entire day productively!’
            </p>
            <a className="button" href="http://app.brutask.com/">
              Get started
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
                <path
                  d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <Intersection />
    </Layout>
  );
};

export default WaitList;
