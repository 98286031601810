import React, { useEffect } from 'react';
import './intersection.scss';

function Intersection() {

  useEffect(() => {
    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const IntersectionSliderContainer = document.querySelector('.intersection-slider');

      if (IntersectionSliderContainer) {
        const IntersectionSlider = tns({
          container: IntersectionSliderContainer,
          autoplay: true,
          autoplayTimeout: 0,
          autoplayButtonOutput: false,
          mouseDrag: false,
          loop: true,
          speed: 4000,
          controls: false,
          nav: false,
          preventActionWhenRunning: true,

          responsive: {
            0: {
              items: 2,
            },

            768: {
              items: 2.5,
            },

            1200: {
              items: 3.5,
            },
          },
        });
      }
    });
  }, []);

  return (
    <div className='intersection'>
      <div className="intersection-slider">
        <div className='flex justify-space-around align-center'>
          <p>Create to-do list</p>
          <div className='flex'>
            <svg
              width='22'
              height='21'
              viewBox='0 0 22 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.3929 0.785714L13.2223 9.56341L22 11L13.2223 12.8969L11.3929 20.8214L9.61104 13.1747L-8.87891e-07 11L9.42857 9.42857L11.3929 0.785714Z'
                fill='#286251'
              />
            </svg>
          </div>
        </div>
        <div className='flex justify-space-around align-center'>
          <p>Timebox and prioritise</p>
          <div className='flex'>
            <svg
              width='22'
              height='21'
              viewBox='0 0 22 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.3929 0.785714L13.2223 9.56341L22 11L13.2223 12.8969L11.3929 20.8214L9.61104 13.1747L-8.87891e-07 11L9.42857 9.42857L11.3929 0.785714Z'
                fill='#286251'
              />
            </svg>
          </div>
        </div>
        <div className='flex justify-space-around align-center'>
          <p>Add team members</p>
          <div className='flex'>
            <svg
              width='22'
              height='21'
              viewBox='0 0 22 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.3929 0.785714L13.2223 9.56341L22 11L13.2223 12.8969L11.3929 20.8214L9.61104 13.1747L-8.87891e-07 11L9.42857 9.42857L11.3929 0.785714Z'
                fill='#286251'
              />
            </svg>
          </div>
        </div>
        <div className='flex justify-space-around align-center'>
          <p>Assign tasks</p>
          <div className='flex'>
            <svg
              width='22'
              height='21'
              viewBox='0 0 22 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.3929 0.785714L13.2223 9.56341L22 11L13.2223 12.8969L11.3929 20.8214L9.61104 13.1747L-8.87891e-07 11L9.42857 9.42857L11.3929 0.785714Z'
                fill='#286251'
              />
            </svg>
          </div>
        </div>
        <div className='flex justify-space-around align-center'>
          <p>Join rooms</p>
          <div className='flex'>
            <svg
              width='22'
              height='21'
              viewBox='0 0 22 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.3929 0.785714L13.2223 9.56341L22 11L13.2223 12.8969L11.3929 20.8214L9.61104 13.1747L-8.87891e-07 11L9.42857 9.42857L11.3929 0.785714Z'
                fill='#286251'
              />
            </svg>
          </div>
        </div>
        <div className='flex justify-space-around align-center'>
          <p>Attend meetings</p>
          <div className='flex'>
            <svg
              width='22'
              height='21'
              viewBox='0 0 22 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.3929 0.785714L13.2223 9.56341L22 11L13.2223 12.8969L11.3929 20.8214L9.61104 13.1747L-8.87891e-07 11L9.42857 9.42857L11.3929 0.785714Z'
                fill='#286251'
              />
            </svg>
          </div>
        </div>
        <div className='flex justify-space-around align-center'>
          <p>Integrate Google Calendar</p>
          <div className='flex'>
            <svg
              width='22'
              height='21'
              viewBox='0 0 22 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.3929 0.785714L13.2223 9.56341L22 11L13.2223 12.8969L11.3929 20.8214L9.61104 13.1747L-8.87891e-07 11L9.42857 9.42857L11.3929 0.785714Z'
                fill='#286251'
              />
            </svg>
          </div>
        </div>
        <div className='flex justify-space-around align-center'>
          <p>Stay connected</p>
          <div className='flex'>
            <svg
              width='22'
              height='21'
              viewBox='0 0 22 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.3929 0.785714L13.2223 9.56341L22 11L13.2223 12.8969L11.3929 20.8214L9.61104 13.1747L-8.87891e-07 11L9.42857 9.42857L11.3929 0.785714Z'
                fill='#286251'
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intersection;
